import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b7b1388 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _27381c26 = () => interopDefault(import('../pages/account-settings.vue' /* webpackChunkName: "pages/account-settings" */))
const _ef347ff4 = () => interopDefault(import('../pages/actions/index.vue' /* webpackChunkName: "pages/actions/index" */))
const _01586c95 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _793439e2 = () => interopDefault(import('../pages/admind/index.vue' /* webpackChunkName: "pages/admind/index" */))
const _f98e21c0 = () => interopDefault(import('../pages/apple.vue' /* webpackChunkName: "pages/apple" */))
const _2b15cd4c = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _5da607c2 = () => interopDefault(import('../pages/building-owners.vue' /* webpackChunkName: "pages/building-owners" */))
const _61d22c9d = () => interopDefault(import('../pages/challenges/index.vue' /* webpackChunkName: "pages/challenges/index" */))
const _79d31e1a = () => interopDefault(import('../pages/cities.vue' /* webpackChunkName: "pages/cities" */))
const _15cf5e21 = () => interopDefault(import('../pages/companies.vue' /* webpackChunkName: "pages/companies" */))
const _a9ddc888 = () => interopDefault(import('../pages/earthday.vue' /* webpackChunkName: "pages/earthday" */))
const _109a902a = () => interopDefault(import('../pages/ecogroups.vue' /* webpackChunkName: "pages/ecogroups" */))
const _2474ea14 = () => interopDefault(import('../pages/explore-challenges.vue' /* webpackChunkName: "pages/explore-challenges" */))
const _bfa2a520 = () => interopDefault(import('../pages/facebook.vue' /* webpackChunkName: "pages/facebook" */))
const _ee6e7f72 = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _1e005f67 = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _ccde88a2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _34d07a98 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _be7824de = () => interopDefault(import('../pages/my-meters.vue' /* webpackChunkName: "pages/my-meters" */))
const _7ba573ea = () => interopDefault(import('../pages/my-usage.vue' /* webpackChunkName: "pages/my-usage" */))
const _3f445004 = () => interopDefault(import('../pages/new_sign_up.vue' /* webpackChunkName: "pages/new_sign_up" */))
const _5703f4ee = () => interopDefault(import('../pages/old_actions/index.vue' /* webpackChunkName: "pages/old_actions/index" */))
const _82fe2296 = () => interopDefault(import('../pages/old_challenges/index.vue' /* webpackChunkName: "pages/old_challenges/index" */))
const _51deb580 = () => interopDefault(import('../pages/old_index.vue' /* webpackChunkName: "pages/old_index" */))
const _1b865897 = () => interopDefault(import('../pages/old_login.vue' /* webpackChunkName: "pages/old_login" */))
const _9f35816c = () => interopDefault(import('../pages/old_syncdata/index.vue' /* webpackChunkName: "pages/old_syncdata/index" */))
const _599a7e5a = () => interopDefault(import('../pages/old-account-settings.vue' /* webpackChunkName: "pages/old-account-settings" */))
const _6b4c88d0 = () => interopDefault(import('../pages/old-my-usage.vue' /* webpackChunkName: "pages/old-my-usage" */))
const _ee6c2b64 = () => interopDefault(import('../pages/organizers.vue' /* webpackChunkName: "pages/organizers" */))
const _e3279904 = () => interopDefault(import('../pages/pilot.vue' /* webpackChunkName: "pages/pilot" */))
const _7475bbe8 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _4163cfc1 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _f1cf2b96 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _54944f66 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _b6a19776 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _a986519c = () => interopDefault(import('../pages/syncdata/index.vue' /* webpackChunkName: "pages/syncdata/index" */))
const _e709d930 = () => interopDefault(import('../pages/terms-conditions.vue' /* webpackChunkName: "pages/terms-conditions" */))
const _0767f2dd = () => interopDefault(import('../pages/admind/background_tasks.vue' /* webpackChunkName: "pages/admind/background_tasks" */))
const _a95b8dda = () => interopDefault(import('../pages/admind/challenges/index.vue' /* webpackChunkName: "pages/admind/challenges/index" */))
const _1f6a839a = () => interopDefault(import('../pages/admind/organizations/index.vue' /* webpackChunkName: "pages/admind/organizations/index" */))
const _feb96d52 = () => interopDefault(import('../pages/admind/pelm_energy_accounts/index.vue' /* webpackChunkName: "pages/admind/pelm_energy_accounts/index" */))
const _6bb09de0 = () => interopDefault(import('../pages/admind/pelm_subscriptions/index.vue' /* webpackChunkName: "pages/admind/pelm_subscriptions/index" */))
const _0f01e390 = () => interopDefault(import('../pages/admind/property/index.vue' /* webpackChunkName: "pages/admind/property/index" */))
const _41a062c9 = () => interopDefault(import('../pages/admind/teams/index.vue' /* webpackChunkName: "pages/admind/teams/index" */))
const _53bdaeca = () => interopDefault(import('../pages/admind/users/index.vue' /* webpackChunkName: "pages/admind/users/index" */))
const _1f21d13c = () => interopDefault(import('../pages/admind/utility_providers/index.vue' /* webpackChunkName: "pages/admind/utility_providers/index" */))
const _0027b1c1 = () => interopDefault(import('../pages/challenges/create.vue' /* webpackChunkName: "pages/challenges/create" */))
const _e08a01ba = () => interopDefault(import('../pages/old_account-settings/account.vue' /* webpackChunkName: "pages/old_account-settings/account" */))
const _ac849c02 = () => interopDefault(import('../pages/old_account-settings/meter.vue' /* webpackChunkName: "pages/old_account-settings/meter" */))
const _9ffce032 = () => interopDefault(import('../pages/old_account-settings/meter_old.vue' /* webpackChunkName: "pages/old_account-settings/meter_old" */))
const _9038d444 = () => interopDefault(import('../pages/old_account-settings/notifications.vue' /* webpackChunkName: "pages/old_account-settings/notifications" */))
const _3867ba57 = () => interopDefault(import('../pages/old_account-settings/team.vue' /* webpackChunkName: "pages/old_account-settings/team" */))
const _3c294ee2 = () => interopDefault(import('../pages/old_account-settings/utility.vue' /* webpackChunkName: "pages/old_account-settings/utility" */))
const _395132a9 = () => interopDefault(import('../pages/old_challenges/create.vue' /* webpackChunkName: "pages/old_challenges/create" */))
const _391b4073 = () => interopDefault(import('../pages/signup/confirm.vue' /* webpackChunkName: "pages/signup/confirm" */))
const _c9a36f60 = () => interopDefault(import('../pages/signup/confirm_email.vue' /* webpackChunkName: "pages/signup/confirm_email" */))
const _4cb59aa6 = () => interopDefault(import('../pages/signup/old_index.vue' /* webpackChunkName: "pages/signup/old_index" */))
const _5179479a = () => interopDefault(import('../pages/signup/send-verification.vue' /* webpackChunkName: "pages/signup/send-verification" */))
const _40c152eb = () => interopDefault(import('../pages/admind/organizations/create.vue' /* webpackChunkName: "pages/admind/organizations/create" */))
const _2903a924 = () => interopDefault(import('../pages/admind/challenges/_challengeId.vue' /* webpackChunkName: "pages/admind/challenges/_challengeId" */))
const _97859568 = () => interopDefault(import('../pages/admind/pelm_energy_accounts/_energyAccountId.vue' /* webpackChunkName: "pages/admind/pelm_energy_accounts/_energyAccountId" */))
const _db959916 = () => interopDefault(import('../pages/admind/pelm_subscriptions/_subscriptionId.vue' /* webpackChunkName: "pages/admind/pelm_subscriptions/_subscriptionId" */))
const _401a5056 = () => interopDefault(import('../pages/admind/property/_propertyId.vue' /* webpackChunkName: "pages/admind/property/_propertyId" */))
const _1face92e = () => interopDefault(import('../pages/admind/teams/_teamId.vue' /* webpackChunkName: "pages/admind/teams/_teamId" */))
const _4f1ee16e = () => interopDefault(import('../pages/admind/users/_userId.vue' /* webpackChunkName: "pages/admind/users/_userId" */))
const _040ff96c = () => interopDefault(import('../pages/admind/utility_providers/_utilId.vue' /* webpackChunkName: "pages/admind/utility_providers/_utilId" */))
const _d01f0c64 = () => interopDefault(import('../pages/challenges/rules/_rule.vue' /* webpackChunkName: "pages/challenges/rules/_rule" */))
const _4f744421 = () => interopDefault(import('../pages/admind/organizations/_organizationId/details.vue' /* webpackChunkName: "pages/admind/organizations/_organizationId/details" */))
const _475929ba = () => interopDefault(import('../pages/admind/organizations/_organizationId/update.vue' /* webpackChunkName: "pages/admind/organizations/_organizationId/update" */))
const _7c28d483 = () => interopDefault(import('../pages/actions/_tipName.vue' /* webpackChunkName: "pages/actions/_tipName" */))
const _1d2715ae = () => interopDefault(import('../pages/challenges/_challenge.vue' /* webpackChunkName: "pages/challenges/_challenge" */))
const _4e50d2ca = () => interopDefault(import('../pages/old_actions/_tipName.vue' /* webpackChunkName: "pages/old_actions/_tipName" */))
const _2729c12f = () => interopDefault(import('../pages/old_syncdata/_subscriptionId.vue' /* webpackChunkName: "pages/old_syncdata/_subscriptionId" */))
const _d41ad1d2 = () => interopDefault(import('../pages/syncdata/_subscriptionId.vue' /* webpackChunkName: "pages/syncdata/_subscriptionId" */))
const _64a138b0 = () => interopDefault(import('../pages/old_challenges/_challenge/_challenge_slug.vue' /* webpackChunkName: "pages/old_challenges/_challenge/_challenge_slug" */))
const _602dced0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _7b7b1388,
    name: "about-us"
  }, {
    path: "/account-settings",
    component: _27381c26,
    name: "account-settings"
  }, {
    path: "/actions",
    component: _ef347ff4,
    name: "actions"
  }, {
    path: "/admin",
    component: _01586c95,
    name: "admin"
  }, {
    path: "/admind",
    component: _793439e2,
    name: "admind"
  }, {
    path: "/apple",
    component: _f98e21c0,
    name: "apple"
  }, {
    path: "/blog",
    component: _2b15cd4c,
    name: "blog"
  }, {
    path: "/building-owners",
    component: _5da607c2,
    name: "building-owners"
  }, {
    path: "/challenges",
    component: _61d22c9d,
    name: "challenges"
  }, {
    path: "/cities",
    component: _79d31e1a,
    name: "cities"
  }, {
    path: "/companies",
    component: _15cf5e21,
    name: "companies"
  }, {
    path: "/earthday",
    component: _a9ddc888,
    name: "earthday"
  }, {
    path: "/ecogroups",
    component: _109a902a,
    name: "ecogroups"
  }, {
    path: "/explore-challenges",
    component: _2474ea14,
    name: "explore-challenges"
  }, {
    path: "/facebook",
    component: _bfa2a520,
    name: "facebook"
  }, {
    path: "/faqs",
    component: _ee6e7f72,
    name: "faqs"
  }, {
    path: "/how-it-works",
    component: _1e005f67,
    name: "how-it-works"
  }, {
    path: "/login",
    component: _ccde88a2,
    name: "login"
  }, {
    path: "/logout",
    component: _34d07a98,
    name: "logout"
  }, {
    path: "/my-meters",
    component: _be7824de,
    name: "my-meters"
  }, {
    path: "/my-usage",
    component: _7ba573ea,
    name: "my-usage"
  }, {
    path: "/new_sign_up",
    component: _3f445004,
    name: "new_sign_up"
  }, {
    path: "/old_actions",
    component: _5703f4ee,
    name: "old_actions"
  }, {
    path: "/old_challenges",
    component: _82fe2296,
    name: "old_challenges"
  }, {
    path: "/old_index",
    component: _51deb580,
    name: "old_index"
  }, {
    path: "/old_login",
    component: _1b865897,
    name: "old_login"
  }, {
    path: "/old_syncdata",
    component: _9f35816c,
    name: "old_syncdata"
  }, {
    path: "/old-account-settings",
    component: _599a7e5a,
    name: "old-account-settings"
  }, {
    path: "/old-my-usage",
    component: _6b4c88d0,
    name: "old-my-usage"
  }, {
    path: "/organizers",
    component: _ee6c2b64,
    name: "organizers"
  }, {
    path: "/pilot",
    component: _e3279904,
    name: "pilot"
  }, {
    path: "/pricing",
    component: _7475bbe8,
    name: "pricing"
  }, {
    path: "/privacy-policy",
    component: _4163cfc1,
    name: "privacy-policy"
  }, {
    path: "/reset_password",
    component: _f1cf2b96,
    name: "reset_password"
  }, {
    path: "/settings",
    component: _54944f66,
    name: "settings"
  }, {
    path: "/signup",
    component: _b6a19776,
    name: "signup"
  }, {
    path: "/syncdata",
    component: _a986519c,
    name: "syncdata"
  }, {
    path: "/terms-conditions",
    component: _e709d930,
    name: "terms-conditions"
  }, {
    path: "/admind/background_tasks",
    component: _0767f2dd,
    name: "admind-background_tasks"
  }, {
    path: "/admind/challenges",
    component: _a95b8dda,
    name: "admind-challenges"
  }, {
    path: "/admind/organizations",
    component: _1f6a839a,
    name: "admind-organizations"
  }, {
    path: "/admind/pelm_energy_accounts",
    component: _feb96d52,
    name: "admind-pelm_energy_accounts"
  }, {
    path: "/admind/pelm_subscriptions",
    component: _6bb09de0,
    name: "admind-pelm_subscriptions"
  }, {
    path: "/admind/property",
    component: _0f01e390,
    name: "admind-property"
  }, {
    path: "/admind/teams",
    component: _41a062c9,
    name: "admind-teams"
  }, {
    path: "/admind/users",
    component: _53bdaeca,
    name: "admind-users"
  }, {
    path: "/admind/utility_providers",
    component: _1f21d13c,
    name: "admind-utility_providers"
  }, {
    path: "/challenges/create",
    component: _0027b1c1,
    name: "challenges-create"
  }, {
    path: "/old_account-settings/account",
    component: _e08a01ba,
    name: "old_account-settings-account"
  }, {
    path: "/old_account-settings/meter",
    component: _ac849c02,
    name: "old_account-settings-meter"
  }, {
    path: "/old_account-settings/meter_old",
    component: _9ffce032,
    name: "old_account-settings-meter_old"
  }, {
    path: "/old_account-settings/notifications",
    component: _9038d444,
    name: "old_account-settings-notifications"
  }, {
    path: "/old_account-settings/team",
    component: _3867ba57,
    name: "old_account-settings-team"
  }, {
    path: "/old_account-settings/utility",
    component: _3c294ee2,
    name: "old_account-settings-utility"
  }, {
    path: "/old_challenges/create",
    component: _395132a9,
    name: "old_challenges-create"
  }, {
    path: "/signup/confirm",
    component: _391b4073,
    name: "signup-confirm"
  }, {
    path: "/signup/confirm_email",
    component: _c9a36f60,
    name: "signup-confirm_email"
  }, {
    path: "/signup/old_index",
    component: _4cb59aa6,
    name: "signup-old_index"
  }, {
    path: "/signup/send-verification",
    component: _5179479a,
    name: "signup-send-verification"
  }, {
    path: "/admind/organizations/create",
    component: _40c152eb,
    name: "admind-organizations-create"
  }, {
    path: "/admind/challenges/:challengeId",
    component: _2903a924,
    name: "admind-challenges-challengeId"
  }, {
    path: "/admind/pelm_energy_accounts/:energyAccountId",
    component: _97859568,
    name: "admind-pelm_energy_accounts-energyAccountId"
  }, {
    path: "/admind/pelm_subscriptions/:subscriptionId",
    component: _db959916,
    name: "admind-pelm_subscriptions-subscriptionId"
  }, {
    path: "/admind/property/:propertyId",
    component: _401a5056,
    name: "admind-property-propertyId"
  }, {
    path: "/admind/teams/:teamId",
    component: _1face92e,
    name: "admind-teams-teamId"
  }, {
    path: "/admind/users/:userId",
    component: _4f1ee16e,
    name: "admind-users-userId"
  }, {
    path: "/admind/utility_providers/:utilId",
    component: _040ff96c,
    name: "admind-utility_providers-utilId"
  }, {
    path: "/challenges/rules/:rule?",
    component: _d01f0c64,
    name: "challenges-rules-rule"
  }, {
    path: "/admind/organizations/:organizationId/details",
    component: _4f744421,
    name: "admind-organizations-organizationId-details"
  }, {
    path: "/admind/organizations/:organizationId/update",
    component: _475929ba,
    name: "admind-organizations-organizationId-update"
  }, {
    path: "/actions/:tipName",
    component: _7c28d483,
    name: "actions-tipName"
  }, {
    path: "/challenges/:challenge",
    component: _1d2715ae,
    name: "challenges-challenge"
  }, {
    path: "/old_actions/:tipName",
    component: _4e50d2ca,
    name: "old_actions-tipName"
  }, {
    path: "/old_syncdata/:subscriptionId",
    component: _2729c12f,
    name: "old_syncdata-subscriptionId"
  }, {
    path: "/syncdata/:subscriptionId",
    component: _d41ad1d2,
    name: "syncdata-subscriptionId"
  }, {
    path: "/old_challenges/:challenge/:challenge_slug?",
    component: _64a138b0,
    name: "old_challenges-challenge-challenge_slug"
  }, {
    path: "/",
    component: _602dced0,
    name: "index"
  }, {
    path: "/confirm_email",
    component: _c9a36f60
  }, {
    path: "/send-verification",
    component: _5179479a
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
